import {
  getWorkOrderLocations,
} from "../../services/assetServices";
import Map from "../../common/mapping/Map";


export interface IWorkOrderMapParams {
  workOrderId: number | undefined;
}

const WorkOrderMap = (params: IWorkOrderMapParams) => (
  <Map
    Id={params.workOrderId}
    getLocations={async (
      abortController: AbortController,
      rentId: number,
      pageSize: number,
      pageNo: number
    ) => await getWorkOrderLocations(abortController, rentId, pageSize, pageNo)}
  />
)


export default WorkOrderMap;
