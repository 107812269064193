import { useContext, useEffect, useRef, useState } from "react";
import PagedList, {
  IListPageColumn,
  IPagedListRefType,
} from "../../common/PagedList";
import {
  getRenters,
  getMetadata,
} from "../../services/assetServices";
import { useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { profileDataAtom } from "../../atoms/authAtoms";
import {
  clearMessageAtom,
  errorMessageAtom,
  isInProgressAtom,
} from "../../atoms/messageBarAtoms";
import { appRoles } from "../../authConfig";
import AppContext from "../AppContext";

const Renters = () => {
  const profileData = useAtomValue(profileDataAtom);
  const navigate = useNavigate();

  const [selectedRenterId, setSelectedRenterId] = useState<number>();

  const pagedListRef = useRef<IPagedListRefType>(null);

  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const clearMessage = useSetAtom(clearMessageAtom);
  const columns = useRef<IListPageColumn[]>([]);

  const fetchData = async () => {
    const abortController = new AbortController();
    setIsInProgress(true);

    try {
      const abortController = new AbortController();

      const metadata = await getMetadata(abortController, "Renter");
      const otherInfoFields = metadata?.fields.filter((mf) => mf.showInGrid);
      otherInfoFields?.forEach((mf) => {
        if (!columns.current.find((c) => c.key === mf.name)) {
          columns.current.push({
            key: mf.name,
            name: mf.name,
            minWidth: 100,
            onRender:
              mf.fieldType !== "Image"
                ? undefined
                : (item: any) => (
                    <img src={item[mf.name]} style={{ width: 20 }} alt="" /> //:
                  ),
          });
        }
      });
      clearMessage();
      context.setSelectedTab("Renters");
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PagedList
        selectedTab="Renters"
        ref={pagedListRef}
        listHeigh={525}
        columns={[
          {
            key: "id",
            name: "Id",
            minWidth: 25,
            maxWidth: 50,
            isResizable: true,
          },
          {
            key: "name",
            name: "Name",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
          },
          {
            key: "code",
            name: "Code",
            minWidth: 70,
            maxWidth: 100,
            isResizable: true,
          },
          {
            key: "createdAt",
            name: "Created at",
            minWidth: 125,
            maxWidth: 155,
            isResizable: true,
          },
          ...columns.current,
        ]}
        getAction={async (abortController, pageSize, pageNo) => {
          const data = await getRenters(abortController, pageSize, pageNo);

          data.items = data.items.map((renter) => {
            const newRenter: any = { ...renter };
            const otherInfoParts = renter.otherInfo.split("|");
            columns.current.map((mf, index) => {
              if (mf?.name) {
                const part = otherInfoParts[index];
                newRenter[mf.name] = part;
              }
            });
            return newRenter;
          });
          return data;
        }}
        commandBarItems={[
          {
            key: "newRenter",
            text: "New",
            iconProps: { iconName: "Add" },
            onClick: () => navigate("/newRenter"),
            disabled: !profileData.roles.includes(appRoles.Admin),
          },
          {
            key: "editRenter",
            text: "Edit",
            iconProps: { iconName: "Edit" },
            onClick: () => navigate(`/editRenter?id=${selectedRenterId}`),
            disabled:
              !profileData.roles.includes(appRoles.Admin) || !selectedRenterId,
          },
          {
            key: "editRents",
            text: "Edit Rents",
            iconProps: { iconName: "RenewalCurrent" },
            onClick: () => navigate(`/rents?id=${selectedRenterId}`),
            disabled:
              !profileData.roles.includes(appRoles.Admin) || !selectedRenterId,
          },
        ]}
        onSelectionChange={(selection: any) => {
          setSelectedRenterId(Number(selection.id));
        }}
      ></PagedList>
    </>
  );
};

export default Renters;
