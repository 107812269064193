import {
  getRentLocations,
} from "../../services/assetServices";
import Map from "../../common/mapping/Map";

export interface IRentMapParams {
  rentId: number | undefined;
}

const RentMap = (params: IRentMapParams) => (
  <Map
    Id={params.rentId}
    getLocations={async (
      abortController: AbortController,
      rentId: number,
      pageSize: number,
      pageNo: number
    ) => await getRentLocations(abortController, rentId, pageSize, pageNo)}
  />
)

export default RentMap;
