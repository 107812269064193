import {
  Dropdown,
  Stack,
  CommandBar,
  ICommandBarItemProps,
  TextField,
  Label,
  DialogFooter,
  DefaultButton,
  Modal,
  DetailsList,
  Text,
  IconButton,
  ITextField,
  IDropdown,
  FontIcon,
  Checkbox,
  ICheckbox,
  mergeStyles,
  getTheme,
  Pivot,
  PivotItem,
  Panel,
  PanelType,
  Separator,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  IDragDropEvents,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { useContext, useEffect, useRef, useState } from "react";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.min.css";
import {
  getMetadata,
  IField,
  ILookup,
  IValidation,
  ILookupValue,
  IMetadata,
  IUpdateMetadataResponse,
  updateMetadata,
  getReferences,
  getUserOrGroupsNames,
  IFieldsTab,
} from "../../services/assetServices";
import ConfirmDialog from "../../common/ConfirmDialog";
import { useSetAtom } from "jotai";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import ValidationEditor from "./ValidationEditor";
import AppContext from "../AppContext";
import UserOrGroupSelector from "../../common/selectors/UsersOrGroupSelectors";
import FieldsTabsEditor from "./FieldsTabsEditor";

export const Metadata = () => {
  const [metadata, setMetadata] = useState<IMetadata>();
  const [metadataStr, setMetadataStr] = useState("");
  const [origMetadataStr, setOrigMetadataStr] = useState("");
  const [entityType, setEntityType] = useState("");
  const [name, setName] = useState("");
  const [currFieldIndex, setCurrFieldIndex] = useState<number>();
  const [references, setReferences] = useState<string[]>([]);
  const [userOrGroupNames, setUserOrGroupNames] =
    useState<Map<string, string>>();
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const context = useContext(AppContext);

  const fetchData = async () => {
    context.setSelectedTab(
      entityType !== "" ? `${entityType} metadata` : "Metadata"
    );
    const abortController = new AbortController();
    try {
      setIsInProgress(true);
      const references = await getReferences(abortController);
      setReferences(references);
    } finally {
      setIsInProgress(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [context.selectedTab]);

  const fetchEntityTypeData = async (entityType: string) => {
    context.setSelectedTab(`${entityType} metadata`);

    const abortController = new AbortController();
    try {
      setIsInProgress(true);
      const data = await getMetadata(abortController, entityType, true);
      setMetadata(data);
      const permissions = data.fields.flatMap((f) => f.permissions);
      if (permissions) {
        const ids = permissions.join("");
        const userOrGroupData = await getUserOrGroupsNames(
          abortController,
          ids
        );
        if (userOrGroupData) {
          setUserOrGroupNames(new Map(Object.entries(userOrGroupData)));
        }
      }
      setMetadataStr(JSON.stringify(data, null, 2));
      setOrigMetadataStr(JSON.stringify(data, null, 2));
      setSelectedLookup(undefined);
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
  };
  const _items: ICommandBarItemProps[] = [
    {
      key: "entityType",
      onRender: () => (
        <Stack horizontal verticalAlign="center">
          <Label style={{ paddingRight: 14 }}>Entity</Label>
          <Dropdown
            style={{ width: 150 }}
            options={[
              { key: "Asset", text: "Asset" },
              { key: "Issue", text: "Issue" },
              { key: "WorkOrder", text: "WorkOrder" },
              { key: "Renter", text: "Renter" },
              { key: "Rent", text: "Rent" },
              { key: "Contractor", text: "Contractor" },
              { key: "User", text: "User" },
              { key: "Group", text: "Group" },
              { key: "Zone", text: "Zone" },
            ]}
            onChange={(ev, option) => {
              setEntityType(option?.key.toString() ?? "");
              setSidePanel(undefined);
              fetchEntityTypeData(option?.key.toString() ?? "");
            }}
          />
        </Stack>
      ),
    },
    {
      key: "name",
      onRender: () => (
        <Stack horizontal verticalAlign="center">
          <Label style={{ paddingRight: 14 }}>Name</Label>
          <TextField onChange={(e, newValue) => setName(newValue ?? "")} />
        </Stack>
      ),
    },
    {
      key: "save",
      name: "Save",
      iconProps: { iconName: "Save" },
      disabled: metadataStr === origMetadataStr,
      onClick: () => {
        if (metadata) {
          try {
            setIsInProgress(true);
            const abortController = new AbortController();
            updateMetadata(abortController, entityType, metadata, name)
              .then((data: IUpdateMetadataResponse) =>
                setSuccessMessage(`Version: ${data.version.toString()}`)
              )
              .catch((err) => setErrorMessage(err.message));
          } finally {
            setIsInProgress(false);
          }
        }
      },
    },
    {
      key: "show",
      name: "Show",
      iconProps: { iconName: "Code" },
      onClick: () => {
        setMetadataStr(JSON.stringify(metadata, null, 2));
        showCodeDialog();
      },
    },
    {
      key: "refresh",
      name: "Refresh",
      iconProps: { iconName: "Refresh" },
      onClick: () => {
        if (metadataStr !== origMetadataStr) {
          showConfirmDialog();
        }
      },
    },
  ];

  const [
    isCodeDialogOpen,
    { setTrue: showCodeDialog, setFalse: hideCodeDialog },
  ] = useBoolean(false);
  const [
    isConfirmDialogOpen,
    { setTrue: showConfirmDialog, setFalse: hideConfirmDialog },
  ] = useBoolean(false);
  const [sidePanel, setSidePanel] = useState<
    | "LookupEditor"
    | "FieldEditor"
    | "ValidationEditor"
    | "PermissionEditor"
    | undefined
  >();

  const [selectedLookup, setSelectedLookup] = useState<ILookup>();
  const [
    isUsersSelectorOpen,
    { setTrue: showUsersSelector, setFalse: hideUsersSelector },
  ] = useBoolean(false);
  const [accesses, setAccesses] = useState<any[]>();
  const [selectedValidation, setSelectedValidation] = useState<IValidation>();
  const [lookupAddOrEdit, setLookupAddOrEdit] = useState<string | undefined>();
  const [editingLookupField, setEditingLookupField] = useState(false);
  const [tempField, setTempField] = useState<IField>();
  const [validationAddOrEdit, setValidationAddOrEdit] = useState<
    string | undefined
  >();
  const [editingValidationField, setEditingValidationField] = useState(false);

  const FieldEditor = () => {
    interface IAccessWithIndex {
      index: number;
      id: number;
      name: string;
      isGroup: boolean;
    }

    const fieldNameRef = useRef<ITextField>(null);
    const fieldLabelRef = useRef<ITextField>(null);
    const fieldTypeRef = useRef<IDropdown>(null);
    const fieldReferencesRef = useRef<IDropdown>(null);
    const fieldDependeesRef = useRef<IDropdown>(null);
    const fieldTabRef = useRef<IDropdown>(null);

    const fieldShowInGridRef = useRef<ICheckbox>(null);
    const [fieldError, setFieldError] = useState<string>();
    const [fieldType, setFieldType] = useState(tempField?.fieldType);
    return (
      <Stack style={{ width: "100%" }}>
        <CommandBar
          items={[
            {
              key: "FieldName",
              onRender: () => <Text variant="large">Field properties</Text>,
            },
          ]}
        />
        <TextField
          defaultValue={tempField?.name}
          label="Name"
          componentRef={fieldNameRef}
        />
        <Dropdown
          options={[
            { key: "String", text: "String" },
            { key: "Number", text: "Number" },
            { key: "NumberWithUnit", text: "NumberWithUnit" },
            { key: "Lookup", text: "Lookup" },
            { key: "Image", text: "Image" },
            { key: "AutoComplete", text: "AutoComplete Lookup" },
            { key: "Date", text: "Date" },
            { key: "Coordinate", text: "Coordinate" },
          ]}
          // defaultSelectedKey={tempField?.fieldType}
          selectedKey={fieldType}
          label="fieldType"
          componentRef={fieldTypeRef}
          onChange={(ev, option) => {
            setFieldType(option?.key.toString() ?? "");
          }}
        />
        <TextField
          defaultValue={tempField?.label}
          label="label"
          componentRef={fieldLabelRef}
        />
        <Dropdown
          label="Tab"
          defaultSelectedKey={tempField?.tabName}
          options={
            metadata?.tabs?.map((tab: IFieldsTab) => ({
              key: tab.tabName,
              text: tab.caption,
            })) ?? []
          }
          componentRef={fieldTabRef}
        />
        <Dropdown
          label="Reference name"
          options={references?.map((r) => ({ key: r, text: r })) ?? []}
          defaultSelectedKey={tempField?.reference}
          styles={{
            root: {
              display:
                fieldType &&
                ["AutoComplete", "Lookup", "NumberWithUnit"].includes(fieldType)
                  ? ""
                  : "none",
            },
          }}
          componentRef={fieldReferencesRef}
        />
        <Dropdown
          label="Dependees"
          options={
            metadata?.fields?.map((f) => ({ key: f.name, text: f.name })) ?? []
          }
          defaultSelectedKeys={tempField?.dependees?.split("|")}
          multiSelect={true}
          styles={{
            root: { display: fieldType !== "AutoComplete" ? "none" : "" },
          }}
          componentRef={fieldDependeesRef}
        />
        <br />
        <Checkbox
          label="Show in grid"
          defaultChecked={tempField?.showInGrid}
          boxSide="end"
          componentRef={fieldShowInGridRef}
        />
        <Separator />
        <Text variant="large">Permissions</Text>
        <CommandBar
          items={[
            {
              key: "addPermission",
              text: "Add",
              iconProps: { iconName: "Add" },
              onClick: () => {
                showUsersSelector();
              },
            },
          ]}
        />
        <DetailsList
          columns={[
            {
              key: "name",
              fieldName: "name",
              name: "User|Group Name",
              minWidth: 120,
            },
            {
              key: "id",
              fieldName: "id",
              name: "User|Group Id",
              minWidth: 110,
              onRender(item, index, column) {
                return (
                  <Stack horizontal grow={true} tokens={{ childrenGap: 5 }}>
                    <Text variant="large">{item.id}</Text>
                    <Text variant="small">
                      {item.isGroup ? `(Group)` : `(User)`}
                    </Text>
                  </Stack>
                );
              },
            },
            {
              key: "action",
              name: "Action",
              minWidth: 85,
              onRender: (item: IAccessWithIndex) => (
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => {
                      const newAccesses = accesses?.filter(
                        (a) => a.id !== item.id
                      );
                      setAccesses(newAccesses);
                    }}
                  />
                </Stack>
              ),
            },
          ]}
          items={
            accesses?.map((a) => ({
              key: a.id,
              id: a.id,
              name: userOrGroupNames?.get(a.name),
              isGroup: a.isGroup,
            })) ?? []
          }
          selectionMode={SelectionMode.none}
        />
        <Stack horizontal horizontalAlign="end">
          <Text variant="medium" style={{ color: "red" }}>
            {fieldError}
          </Text>
          <IconButton
            iconProps={{ iconName: "CheckMark" }}
            onClick={() => {
              const selectedFieldType =
                fieldTypeRef.current?.selectedOptions[0].key.toString();
              if (!selectedFieldType) {
                setFieldError("Type should be selected");
                return;
              }
              const selectedFieldTab = 
                fieldTabRef.current?.selectedOptions?.length ?
                fieldTabRef.current?.selectedOptions[0].key.toString():
                undefined;
              const selectedFieldReference =
                fieldReferencesRef.current?.selectedOptions[0]?.key.toString();
              setFieldError(undefined);
              if (
                ["Lookup", "AutoComplete", "NumberWithUnit"].includes(
                  selectedFieldType
                ) &&
                selectedFieldReference === undefined
              ) {
                setFieldError(
                  `Type selected as ${selectedFieldType}, but missing lookup`
                );
                return;
              }
              if (metadata && tempField) {
                const fields = metadata?.fields;
                const newOrEditedField: IField = {
                  name: fieldNameRef.current?.value ?? "",
                  label: fieldLabelRef.current?.value ?? "",
                  tabName: selectedFieldTab ?? "",
                  fieldType: selectedFieldType ?? "",
                  lookupList: selectedFieldReference ?? "",
                  dependees: fieldDependeesRef.current?.selectedOptions
                    .map((o) => o.key)
                    .join("|"),
                  reference:
                    fieldReferencesRef.current?.selectedOptions[0]?.key?.toString() ??
                    "",
                  showInGrid: fieldShowInGridRef.current?.checked ?? false,
                  permissions: accesses?.map(
                    (a) => `${a.isGroup ? "G" : "U"}${a.id}`
                  ),
                };
                if (currFieldIndex !== undefined) {
                  fields[currFieldIndex] = newOrEditedField;
                } else {
                  fields.push(newOrEditedField);
                }
                const newMetadata: IMetadata = {
                  lookups: metadata.lookups ?? [],
                  fields: [...(metadata?.fields ?? [])],
                  validations: [...(metadata?.validations ?? [])],
                  tabs: [...(metadata.tabs ?? [])],
                };
                setMetadata(newMetadata);
                setMetadataStr(JSON.stringify(newMetadata, null, 2));
              }
              setSidePanel(undefined);
            }}
          />
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={() => {
              setCurrFieldIndex(undefined);
              setTempField(undefined);
              setSidePanel(undefined);
            }}
          />
        </Stack>
        <UserOrGroupSelector
          isOpen={isUsersSelectorOpen}
          hideUsersAndGroupsSelector={hideUsersSelector}
          onUserOrGroupSelected={(
            id: number,
            isGroup: boolean,
            username?: string
          ) => {
            const userOrGroupKey = `${isGroup ? "G" : "U"}${id}`;
            userOrGroupNames?.set(userOrGroupKey, username ?? "");
            if (accesses) {
              setAccesses([
                ...accesses,
                { id: id, name: userOrGroupKey, isGroup: isGroup, email: "" },
              ]);
            } else {
              setAccesses([
                { id: id, name: userOrGroupKey, isGroup: isGroup, email: "" },
              ]);
            }
          }}
        />
      </Stack>
    );
  };

  const FieldsEditor = () => {
    interface IFieldWithIndex extends IField {
      index: number;
    }

    let draggedItem: IField | undefined;
    let draggedIndex: number = -1;
    const insertBeforeItem = (item: IField): void => {
      const insertIndex =
        metadata?.fields?.findIndex((f) => f.name === item.name) ?? -1;
      if (insertIndex === -1 || draggedItem === undefined) {
        return;
      }
      const newFields = metadata?.fields?.filter(
        (f) => f.name !== draggedItem?.name
      );
      newFields?.splice(insertIndex, 0, draggedItem);
      const newMetadata: IMetadata = {
        lookups: metadata?.lookups ?? [],
        fields: newFields ?? [],
        validations: metadata?.validations ?? [],
        tabs: metadata?.tabs ?? [],
      };
      setMetadata(newMetadata);
      setMetadataStr(JSON.stringify(newMetadata, null, 2));
    };
    const theme = getTheme();
    const dragEnterClass = mergeStyles({
      backgroundColor: theme.palette.neutralLight,
      borderTopColor: "black",
      borderTopStyle: "solid",
    });
    const getDragDropEvents = () => {
      return {
        canDrop: (dropContext?: any, dragContext?: any) => {
          return true;
        },
        canDrag: (item?: any) => {
          return true;
        },
        onDrop: (item?: IField, event?: DragEvent) => {
          if (draggedItem && item) {
            insertBeforeItem(item);
          }
        },
        onDragStart: (
          item?: any,
          itemIndex?: number,
          selectedItems?: any[],
          event?: MouseEvent
        ) => {
          if (typeof item.startIndex === "undefined") {
            // skip group item
            draggedItem = item;
            draggedIndex = itemIndex!;
          }
        },
        onDragEnd: (item?: any, event?: DragEvent) => {
          draggedItem = undefined;
          draggedIndex = -1;
        },
        onDragEnter: (item?: any, event?: DragEvent) => {
          // return string is the css classes that will be added to the entering element.
          return dragEnterClass;
        },
      };
    };
    const dragDropEvents: IDragDropEvents = getDragDropEvents();

    return (
      <Stack>
        <CommandBar
          items={[
            {
              key: "AddField",
              text: "Add",
              iconProps: { iconName: "Add" },
              onClick: () => {
                setTempField({
                  name: "",
                  label: "",
                  fieldType: "",
                  lookupList: "",
                  showInGrid: false,
                });
                setCurrFieldIndex(undefined);
                setAccesses([]);
                setSidePanel("FieldEditor");
              },
            },
          ]}
        />
        <DetailsList
          columns={[
            {
              key: "name",
              name: "Name",
              fieldName: "name",
              minWidth: 100,
            },
            {
              key: "fieldType",
              name: "Type",
              fieldName: "fieldType",
              minWidth: 80,
            },
            {
              key: "label",
              name: "Label",
              fieldName: "label",
              minWidth: 100,
            },
            {
              key: "source",
              name: "Source",
              fieldName: "lookupList",
              minWidth: 80,
              onRender: (item: IFieldWithIndex) => (
                <Stack>
                  <span>
                    {item.fieldType === "Lookup"
                      ? item.lookupList
                      : item.fieldType === "AutoComplete"
                      ? item.reference
                      : ""}
                  </span>
                  <Text variant="small">
                    {item.fieldType === "Lookup"
                      ? "(lookup)"
                      : item.fieldType === "AutoComplete"
                      ? "(source)"
                      : ""}
                  </Text>
                </Stack>
              ),
            },
            {
              key: "showInGrid",
              name: "Grid",
              fieldName: "showInGrid",
              minWidth: 35,
              onRender: (item: IFieldWithIndex) =>
                item.showInGrid ? (
                  <FontIcon
                    iconName="StatusCircleCheckmark"
                    style={{ width: 25, height: 25, fontSize: 25 }}
                  />
                ) : (
                  <></>
                ),
            },
            {
              key: "action",
              name: "Action",
              minWidth: 50,
              onRender: (item: IFieldWithIndex) => (
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: "Edit" }}
                    onClick={() => {
                      setSelectedLookup(undefined);
                      const field = metadata?.fields?.find(
                        (p) => p.name === item.name
                      );
                      if (field?.permissions) {
                        setAccesses(
                          field.permissions.map((a) => ({
                            id: a.replace("U", "").replace("G", ""),
                            name: a,
                            isGroup: a.startsWith("G"),
                          }))
                        );
                      }
                      setCurrFieldIndex(item.index);
                      setTempField(item);
                      setSidePanel("FieldEditor");
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => {
                      const newMetadata: IMetadata = {
                        lookups: metadata?.lookups ?? [],
                        fields:
                          metadata?.fields.filter(
                            (f) => f.name !== item.name
                          ) ?? [],
                        validations: metadata?.validations ?? [],
                        tabs: metadata?.tabs ?? [],
                      };
                      setMetadata(newMetadata);
                      setMetadataStr(JSON.stringify(newMetadata, null, 2));
                    }}
                  />
                </Stack>
              ),
            },
          ]}
          items={metadata?.fields?.map((f, index) => ({ ...f, index })) ?? []}
          selectionMode={SelectionMode.none}
          dragDropEvents={dragDropEvents}
        />
      </Stack>
    );
  };

  const ValidationsEditor = () => {
    interface IValidationWithIndex extends IValidation {
      index: number;
    }

    return (
      <Stack>
        <CommandBar
          items={[
            {
              key: "AddValidation",
              text: "Add",
              iconProps: { iconName: "Add" },
              onClick: () => {
                setValidationAddOrEdit("add");
                setEditingValidationField(true);
                setSidePanel("ValidationEditor");
              },
            },
          ]}
        />
        <DetailsList
          columns={[
            {
              key: "field",
              name: "Field",
              fieldName: "field",
              minWidth: 100,
            },
            {
              key: "action",
              name: "Action",
              minWidth: 85,
              onRender: (item: IValidationWithIndex) => (
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: "Edit" }}
                    onClick={() => {
                      setSelectedValidation(item);
                      setValidationAddOrEdit("edit");
                      setEditingValidationField(false);
                      setSidePanel("ValidationEditor");
                    }}
                  />
                  <IconButton
                    iconProps={{ iconName: "Delete" }}
                    onClick={() => {
                      const newMetadata: IMetadata = {
                        ...metadata,
                        lookups: metadata?.lookups ?? [],
                        fields: metadata?.fields ?? [],
                        validations:
                          metadata?.validations.filter(
                            (v) => v.field !== item.field
                          ) ?? [],
                        tabs: metadata?.tabs ?? [],
                      };
                      setMetadata(newMetadata);
                      setMetadataStr(JSON.stringify(newMetadata, null, 2));
                      setSelectedValidation(undefined);
                    }}
                  />
                </Stack>
              ),
            },
          ]}
          items={
            metadata?.validations?.map((v, index) => ({
              ...v,
              index: index,
            })) ?? []
          }
          selectionMode={SelectionMode.none}
        />
      </Stack>
    );
  };

  return (
    <Stack>
      <Text>Please select an Entity type from Entity tpe dropdown.</Text>
      <p></p>
      <CommandBar items={_items} />
      <Stack horizontal verticalAlign="stretch">
        <Pivot defaultSelectedKey="fields">
          <PivotItem headerText="Fields" itemKey="fields">
            <FieldsEditor />
          </PivotItem>
          <PivotItem headerText="Tabs" itemKey="tabs">
            <FieldsTabsEditor
              fieldsTab={metadata?.tabs}
              onChange={(newValue: IFieldsTab[]) => {
                const newMetadata: IMetadata = {
                  lookups: metadata?.lookups ?? [],
                  fields: [...(metadata?.fields ?? [])],
                  validations: [...(metadata?.validations ?? [])],
                  tabs: newValue,
                };
                setMetadata(newMetadata);
                setMetadataStr(JSON.stringify(metadata, null, 2));
              }}
            />
          </PivotItem>
          <PivotItem headerText="Validations" itemKey="validations">
            <ValidationsEditor />
          </PivotItem>
        </Pivot>
        <Panel
          isOpen={sidePanel !== undefined}
          onDismiss={() => setSidePanel(undefined)}
          closeButtonAriaLabel="Close"
          type={
            sidePanel === "FieldEditor"
              ? PanelType.medium
              : PanelType.smallFixedFar
          }
          headerText={sidePanel}
        >
          {sidePanel === "FieldEditor" ? (
            <FieldEditor />
          ) : sidePanel === "ValidationEditor" ? (
            <ValidationEditor
              selectedValidation={selectedValidation}
              setSelectedValidation={(validation) =>
                setSelectedValidation(validation)
              }
              setSidePanel={(panel) => setSidePanel(panel)}
              metadata={metadata}
              setMetadataStr={(metadataStr) =>
                setMetadataStr(metadataStr ?? "")
              }
              validationAddOrEdit={validationAddOrEdit}
              setValidationAddOrEdit={(validationAddOrEdit) =>
                setValidationAddOrEdit(validationAddOrEdit)
              }
              editingValidationField={editingValidationField}
              setEditingValidationField={(editingValidationField) =>
                setEditingValidationField(editingValidationField)
              }
              setMetadata={(metadata) => setMetadata(metadata)}
            />
          ) : (
            <></>
          )}
        </Panel>
      </Stack>
      <Modal isOpen={isCodeDialogOpen} onDismiss={hideCodeDialog}>
        <div style={{ height: 500, overflow: "auto" }}>
          <Editor
            value={metadataStr}
            onValueChange={(code) => setMetadataStr(code)}
            highlight={(code) =>
              Prism.highlight(code, Prism.languages.js, "js")
            }
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              width: "500px",
            }}
          />
        </div>
        <DialogFooter>
          <DefaultButton onClick={hideCodeDialog} text="Close" />
        </DialogFooter>
      </Modal>
      <ConfirmDialog
        isModalOpen={isConfirmDialogOpen}
        hideModal={hideConfirmDialog}
        message="All your metadata changes would be lost. Are you sure."
        onYesClick={() => {
          fetchEntityTypeData(entityType);
          setSelectedLookup(undefined);
          setSidePanel(undefined);
          hideConfirmDialog();
        }}
      />
    </Stack>
  );
};
