import { useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import {
  ICreateRentRequest,
  IMetadata,
  IUpdateRentRequest,
  createRent,
  editRent,
  getRent,
  getMetadata,
  IRent,
  IAsset,
} from "../../services/assetServices";
import { useSetAtom } from "jotai";
import { successMessageAtom } from "../../atoms/messageBarAtoms";
import { Label, Pivot, PivotItem, Stack, TextField } from "@fluentui/react";
import { useLocation } from "react-router-dom";
import OtherInfo from "../../common/OtherInfo";
import {
  labelColumnStyle,
  valueColumnStyle,
} from "../../common/styles/FormsStyles";
import AssetSelector from "../workOrders/AssetSelector";
import WorkOrderMap from "../workOrders/WorkOrderMap";
import RentMap from "./RentMap";

const Rent = () => {
  const query = new URLSearchParams(useLocation().search);
  const itemId = Number.parseInt(query.get("id") ?? "");
  const renterId = Number.parseInt(query.get("renterId") ?? "");

  const [metadata, setMetadata] = useState<IMetadata>();
  const [otherInfo, setOtherInfo] = useState({});

  const [rentId, setRentId] = useState<number>(itemId);
  const [renterName, setRenterName] = useState<string>();
  const setSuccessMessage = useSetAtom(successMessageAtom);

  const [assetId, setAssetId] = useState<number>();
  const [assetName, setAssetName] = useState<string>();

  const isSaved = useRef(false);

  return (
    <Pivot>
      <PivotItem headerText="Details">
        <Stack tokens={{ childrenGap: 5 }}>
          <Stack horizontal>
            <Label style={labelColumnStyle}>Renter</Label>
            <TextField
              style={{ width: valueColumnStyle.width - 2 }}
              value={renterName}
              readOnly
              borderless
            />
          </Stack>
          <AssetSelector
            disabled={isSaved.current}
            onSelected={(asset: IAsset) => {
              setAssetId(asset.id);
            }}
            assetName={assetName}
          />
          <EditItem
            getAction={async (abortController, id) => {
              const rent: IRent = await getRent(abortController, id);
              setRentId(id);
              setAssetName(rent.assetName);
              setRenterName(rent.renterName);
              setAssetId(rent.assetId);
              const metadata = await getMetadata(abortController, "Rent");
              setOtherInfo(JSON.parse(rent.otherInfo));
              setMetadata(metadata);
              rent.statusId = rent.statusId;
              return rent;
            }}
            newAction={async (abortController, newRent) => {
              const data: ICreateRentRequest = {
                assetId: assetId ?? 0,
                renterId: renterId,
                code: newRent.code,
                description: newRent.description,
                otherInfo: JSON.stringify(otherInfo),
                agreedPrice: newRent.agreedPrice,
                agreedPriceUnit: newRent.agreedPriceUnit,
                fromDate: newRent.fromDate,
                toDate: newRent.toDate,
                statusId: Number.parseInt(newRent.statusId),
                status: newRent.statusId,
              };
              const newRentResponse = await createRent(abortController, data);
              setSuccessMessage(
                `Rent with id: ${newRentResponse.rentId} created.`
              );
            }}
            editAction={async (abortController, id, editedRent) => {
              const data: IUpdateRentRequest = {
                assetId: assetId ?? 0,
                renterId: renterId,
                description: editedRent.description,
                code: editedRent.code,
                otherInfo: JSON.stringify(otherInfo),
                agreedPrice: editedRent.agreedPrice,
                agreedPriceUnit: editedRent.agreedPriceUnit,
                fromDate: editedRent.fromDate,
                toDate: editedRent.toDate,
                statusId: Number.parseInt(editedRent.statusId),
                status: editedRent.status,
              };
              await editRent(abortController, id, data);
              setSuccessMessage(`Rent with id: ${id} successfully updated.`);
            }}
            isSaved={isSaved}
            back={`/rents?id=${renterId}`}
            metadata={{
              fields: [
                {
                  name: "description",
                  fieldType: "String",
                  label: "Description",
                },
                {
                  name: "code",
                  fieldType: "String",
                  label: "Code",
                },
                {
                  name: "agreedPrice",
                  fieldType: "Number",
                  label: "Agreed Price",
                },
                {
                  name: "agreedPriceUnit",
                  fieldType: "String",
                  label: "Price Unit",
                },
                {
                  name: "fromDate",
                  fieldType: "Date",
                  label: "From Date",
                },
                {
                  name: "toDate",
                  fieldType: "Date",
                  label: "To Date",
                },
                {
                  name: "statusId",
                  fieldType: "Lookup",
                  label: "Status Id",
                  lookupList: "RentStatuses",
                },
                {
                  name: "status",
                  fieldType: "String",
                  label: "Status",
                },
              ],
              lookups: [
                {
                  name: "RentStatuses",
                  values: [
                    {
                      key: "1",
                      value: "Pending",
                    },
                    {
                      key: "2",
                      value: "Rented",
                    },
                    {
                      key: "3",
                      value: "Returned",
                    },
                    {
                      key: "4",
                      value: "Canceled",
                    },
                  ],
                },
              ],
              validations: [],
            }}
          >
            <>
              {metadata ? (
                <OtherInfo
                  metadata={metadata}
                  otherInfo={JSON.stringify(otherInfo)}
                  isSaved={isSaved}
                  onOtherInfoChanged={(newValue: string) =>
                    setOtherInfo(newValue)
                  }
                />
              ) : (
                <></>
              )}
            </>
          </EditItem>
        </Stack>
      </PivotItem>
      <PivotItem headerText="Location">
        <RentMap rentId={rentId} />
      </PivotItem>
    </Pivot>
  );
};

export default Rent;
