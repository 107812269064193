import { useRef, useState } from "react";
import EditItem from "../../common/EditItem";
import {
  ICreateRenterRequest,
  IMetadata,
  IUpdateRenterRequest,
  IGroup,
  createRenter,
  editRenter,
  getRenter,
  getMetadata,
} from "../../services/assetServices";
import { useSetAtom } from "jotai";
import {
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import {
  Stack,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useLocation } from "react-router-dom";
import OtherInfo from "../../common/OtherInfo";
import UserOrGroupSelector from "../../common/selectors/UsersOrGroupSelectors";

const Renter = () => {
  const query = new URLSearchParams(useLocation().search);
  const itemId = Number.parseInt(query.get("id") ?? "");

  const [metadata, setMetadata] = useState<IMetadata>();
  const [otherInfo, setOtherInfo] = useState({});

  const [renterId, setRenterId] = useState<number>(itemId);
  const [group, setGroup] = useState<IGroup>();
  const setSuccessMessage = useSetAtom(successMessageAtom);

  const [
    isUserSelectorOpen,
    { setTrue: showUserSelector, setFalse: hideUserSelector },
  ] = useBoolean(false);

  const isSaved = useRef(false);
  
  return (
    <>
      <EditItem
        getAction={async (abortController, id) => {
          const renter: any = await getRenter(abortController, id);
          setRenterId(id);
          const metadata = await getMetadata(abortController, "Renter");
          setOtherInfo(JSON.parse(renter.otherInfo));
          setMetadata(metadata);
          renter.statusId = (renter.statusId ?? "").toString();
          return renter;
        }}
        newAction={async (abortController, newWorkOrder) => {
          const data: ICreateRenterRequest = {
            code: newWorkOrder.code,
            name: newWorkOrder.name,
            otherInfo: JSON.stringify(otherInfo),
          };
          const newRenterResponse = await createRenter(
            abortController,
            data
          );
          setSuccessMessage(
            `Renter with id: ${newRenterResponse.renterId} created.`
          );
        }}
        editAction={async (abortController, id, editedWorkOrder) => {
          const data: IUpdateRenterRequest = {
            name: editedWorkOrder.name,
            code: editedWorkOrder.code,
            otherInfo: JSON.stringify(otherInfo),
          };
          await editRenter(abortController, id, data);
          setSuccessMessage(`Renter with id: ${id} successfully updated.`);
        }}
        isSaved={isSaved}
        back={"/renters"}
        metadata={{
          fields: [
            {
              name: "name",
              fieldType: "String",
              label: "Name",
            },
            {
              name: "code",
              fieldType: "String",
              label: "Code",
            },
          ],
          lookups: [],
          validations: [],
        }}
      >
        <>
          <Stack horizontal>
            <UserOrGroupSelector
              userOrGroupOnly={"group"}
              isOpen={isUserSelectorOpen}
              onUserOrGroupSelected={(
                id: number,
                isGroup: boolean,
                username?: string
              ) => {
                setGroup({
                  id,
                  name: username ?? "",
                });
              }}
              hideUsersAndGroupsSelector={hideUserSelector}
            />
          </Stack>

          {metadata ? (
            <OtherInfo
              metadata={metadata}
              otherInfo={JSON.stringify(otherInfo)}
              isSaved={isSaved}
              onOtherInfoChanged={(newValue: string) => setOtherInfo(newValue)}
            />
          ) : (
            <></>
          )}
        </>
      </EditItem>
    </>
  );
};

export default Renter;
